<template>
    <div>
        <s-form class="color-txt" @submit="addItem" ref="form">
            <h3 class="text-primary mt-2 mb-4 font-poppins-semibold fs-lg-4">Bull Disease Screening</h3>
            <div class="row">
                <div class="col-lg-2">
                    <validated-ajax-vue-select class="c-input-select field-required"
                                               :url="diseaseTypeOptionURL"
                                               label="Disease Type" v-model="model.disease_type" :rules="{required:true}"></validated-ajax-vue-select>
                </div>
                <div class="col-lg-2">
                    <validated-date-picker label="Screening Date" format="DD-MM-YYYY"
                                           class="c-input-datepicker field-required" v-model="model.screening_date" :rules="{required:true}"></validated-date-picker>
                </div>
                <div class="col-lg-2">
                    <validated-ajax-vue-select
                        :url="frequencyOptionURL"
                        class="c-input-select field-required" label="Screening Frequency" v-model="model.screening_frequency" :rules="{required:true}"></validated-ajax-vue-select>
                </div>
                <div class="col-lg-2">
                    <validated-ajax-vue-select class="c-input-select"
                                               :url="agencyOptionURl"
                                               label="Agency" v-model="model.agency"></validated-ajax-vue-select>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-2">
                    <validated-date-picker label="Result Date" format="DD-MM-YYYY"
                                           class="c-input-datepicker field-required" v-model="model.result_date" :rules="rules.common"></validated-date-picker>
                </div>
                <div class="col-lg-2">
                    <validated-ajax-vue-select class="c-input-select field-required" @input="loadBullOptions"
                                               :url="shedOptionsURL"
                                               label="Shed No" v-model="model.shed_number" :rules="{required:true}"></validated-ajax-vue-select>
                </div>
                <div class="col-lg-2">
                    <validated-vue-select multiple @input="calculateBulls"
                        :options="bullOptions" :show-loading-prepend="dataLoading"
                        class="c-input-select-multiplevalue field-required" label="Animals" v-model="model.bull" :rules="{required:true}"></validated-vue-select>
                </div>
                <div class="col-lg-2">
                    <validated-select
                        :options="statusOptionURL"
                        class="c-input-select" label="Status" v-model="model.status"></validated-select>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-2">
                    <validated-input label="Total Animals(in Shed)" :show-loading-prepend="dataLoading"
                                     disabled
                                     class="" v-model="model.total_animals"></validated-input>
                </div>
                <div class="col-lg-2">
                    <validated-input label="No of animals positive" :show-loading-prepend="dataLoading"
                                     disabled
                                     v-model="model.no_of_animals_positive"></validated-input>
                </div>
                <div class="col-lg-2">
                    <validated-input label="No of animals negative" :show-loading-prepend="dataLoading"
                                     disabled
                                     v-model="model.no_of_animals_negative"></validated-input>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-2">
                    <validated-input label="Remarks"
                                     class="" v-model="model.remarks"></validated-input>
                </div>
                <div class="col-lg-2 mt-4">
                    <validated-file-input label-text="Upload pdf" v-model="model.screening_file" class="w-10r"></validated-file-input>
                </div>
            </div>
            <div class="row mt-lg-7">
                <div class="col-12">
                    <div class="fl-x fl-j-e">
                        <div class="btn-group">
                            <btn text="Save" :loading="loading" loading-text="Saving..." class="px-4"></btn>
                            <btn text="Cancel" design="basic-b" type="reset" @click="BackToHome" class="px-4"></btn>
                        </div>
                    </div>
                </div>
            </div>
        </s-form>
    </div>
</template>

<script>
import axios from 'secure-axios';
import masterURLs from '../../data/masterURLs';
import urls from '../../data/urls';

export default {
    name: 'BullDiseaseScreening',
    data () {
        return {
            loading: false,
            dataLoading: false,
            URL: urls.diseaseScreening.addEdit,
            agencyOptionURl: masterURLs.master.agency.vueSelect,
            shedOptionsURL: masterURLs.master.shed.vueSelect + '?animal=bull',
            diseaseTypeOptionURL: masterURLs.master.diseaseType.vueSelect,
            frequencyOptionURL: masterURLs.master.frequency.vueSelect,
            statusOptionURL: [
                { label: 'Positive', value: 'positive' },
                { label: 'Negative ', value: 'negative ' },
                { label: 'Recheck ', value: 'recheck ' }
            ],
            model: {
                disease_type: '',
                screening_date: '',
                agency: '',
                screening_frequency: '',
                result_date: '',
                shed_number: '',
                status: '',
                total_animals: '',
                no_of_animals_positive: '',
                no_of_animals_negative: '',
                remarks: '',
                bull: [],
                screening_file: ''
            },
            bullOptions: [],
            rules: {
                common: {
                    required: true,
                    customValidator: (value) => {
                        return this.Validation(value);
                    }
                }
            }
        };
    },

    methods: {
        async loadBullOptions () {
            this.dataclear();
            this.dataLoading = true;
            const response = await axios.get(urls.bulls.vueSelect + '?shed_number=' + this.model.shed_number);
            this.bullOptions = response.data.data;
            this.dataLoading = false;
            this.totalAnimals();
        },
        totalAnimals () {
            this.model.total_animals = this.bullOptions.length;
            this.model.no_of_animals_negative = this.bullOptions.length;
        },
        calculateBulls () {
            console.log(this.model.bull.length);
            const selectedBulls = this.model.bull.length;
            const totalBulls = this.bullOptions.length;
            const remainingBulls = totalBulls - selectedBulls;
            this.model.no_of_animals_positive = selectedBulls;
            this.model.no_of_animals_negative = remainingBulls;
        },
        dataclear () {
            this.model.bull = '';
            this.$refs.form.reset();
        },
        Validation (date) {
            const screeningdate = this.model.screening_date;
            const dateParts = date.split('-');

            if (dateParts.length !== 3) {
                return 'Please enter a valid AI date in the format DD-MM-YYYY.';
            }
            const aiDay = parseInt(dateParts[0], 10);
            const aiMonth = parseInt(dateParts[1], 10);
            const aiYear = parseInt(dateParts[2], 10);

            const screeningdateParts = screeningdate.split('-');
            if (screeningdateParts.length !== 3) {
                return 'Please enter a valid DOB in the format DD-MM-YYYY.';
            }
            const screeningdateDay = parseInt(screeningdateParts[0], 10);
            const screeningdateMonth = parseInt(screeningdateParts[1], 10);
            const screeningdateYear = parseInt(screeningdateParts[2], 10);

            if (isNaN(aiDay) || isNaN(aiMonth) || isNaN(aiYear) || isNaN(screeningdateDay) || isNaN(screeningdateMonth) || isNaN(screeningdateYear)) {
                return 'Please enter valid dates in the format DD-MM-YYYY.';
            }

            const dateObj = new Date(aiYear, aiMonth - 1, aiDay);
            const screeningdateDateObj = new Date(screeningdateYear, screeningdateMonth - 1, screeningdateDay);

            if (dateObj <= screeningdateDateObj) {
                return 'Date should be after the Screening date';
            }

            return true;
        },
        async addItem () {
            try {
                this.loading = true;
                const response = await axios.form(this.URL, this.model);
                const result = response.data;
                if (result.success) {
                    this.$notify('Added Successfully', 'Success', { type: 'success' });
                    this.loading = false;
                    await this.$router.push({ path: '/semen-station/' });
                } else {
                    const errors = response.data.errors;

                    for (const key in errors) {
                        if (Object.prototype.hasOwnProperty.call(errors, key)) {
                            const errorMessage = `${key}:  ${errors[key]}`;
                            this.$notify(errorMessage, 'Warning', { type: 'warning' });
                        }
                    }
                    this.loading = false;
                }
            } catch (error) {
                this.$notify(
                    'Something Went Wrong..!!',
                    error.response.status + ' ' + error.response.statusText,
                    { type: 'danger' }
                );
                this.loading = false;
            }
        },
        BackToHome () {
            this.$router.push({ path: '/semen-station/' });
        }
    }

};
</script>

<style scoped>

</style>
